<template>
  <v-container>
    <v-overlay :value="loadingData" v-if="loadingData">
      <v-progress-circular color="primary" indeterminate size="164">
        <v-avatar size="70">
          <v-img src="@/assets/logo1.svg"> </v-img>
        </v-avatar>
      </v-progress-circular>
    </v-overlay>
    <add-goal v-if="isInRole(0)" @addGoal="getGoals(id)"></add-goal>
    <edit-goal
      v-if="isInRole(0)"
      @editGoal="n => editedItem(n)"
      @editDialog="n => (editDialog = n.value)"
      :currantDate="model.dueDate"
      :currantTitle="model.title"
      :currentProgressStatus="model.progressStatus"
      :currentVisible="model.visible"
      :currantDescription="model.description"
      :id="model.goalGuid"
      :editDialog="editDialog"
    ></edit-goal>
    <v-bottom-navigation absolute style="z-index: 2;">
      <v-pagination
        class="py-1"
        v-model="page"
        :length="Math.ceil(goals.length / perPage)"
        total-visible="15"
      ></v-pagination>
    </v-bottom-navigation>
    <v-row no-gutters v-if="goals.length !== 0 || loadingData" style="margin-bottom: 100px">
      <v-card class="d-flex rounded-lg pa-4 my-6 mx-1" width="100%" flat>
        <v-text-field
          hide-details
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('writeWhatYouSearch')"
          outlined
          dense
          class="ma-auto"
        >
          <template v-slot:append-outer>
            <v-card-actions style="margin-top: -19px" class="pt-0"> </v-card-actions>
          </template>
        </v-text-field>
        <v-spacer></v-spacer>
        <v-btn tile color="primary" @click="back()" class="rounded-xl">
          {{ $t("back") }}
          <v-icon right> mdi-arrow-left </v-icon>
        </v-btn>
      </v-card>

      <v-col
        v-for="(item, index) in goalsFilter"
        :key="index"
        class="px-0 py-1"
        cols="12"
        xl="3"
        lg="3"
        md="6"
        sm="12"
      >
        <v-card flat height="300" class="pa-0 mx-1">
          <v-card-title>
            {{ item.title }}
            <v-spacer />
            <v-menu bottom max-height="250">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <show-goal :description="item.description"></show-goal>
                <v-list-item v-if="isInRole(0)" @click="setModel(item)">
                  <v-list-item-title>{{ $t("edit") }}</v-list-item-title>
                  <v-list-item-icon>
                    <v-icon>mdi-pen</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-dialog
                  v-if="isInRole(0)"
                  v-model="deleteDialog"
                  transition="dialog-bottom-transition"
                  max-width="400"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item v-bind="attrs" v-on="on">
                      <v-list-item-title>{{ $t("delete") }}</v-list-item-title>
                      <v-list-item-icon>
                        <v-icon>mdi-delete</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </template>
                  <template v-slot:default="dialog">
                    <v-card>
                      <v-toolbar color="error" dark>
                        {{ $t("portfolio.Goals.confirmDelete") }}</v-toolbar
                      >
                      <v-card-text>
                        <h3 class="pa-8">
                          {{
                            $t("portfolio.Goals.confirmDeleteMessage").replace("title", item.title)
                          }}
                        </h3>
                      </v-card-text>
                      <v-card-actions class="justify-end">
                        <v-btn text @click="dialog.value = false">{{ $t("close") }}</v-btn>
                        <v-btn color="error" @click="removeModel(item)">{{ $t("ok") }}</v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
                <feedback-dialog :sourceGuid="item.goalGuid" :sourceType="3" />
                <comments-dialog :sourceGuid="item.goalGuid" :sourceType="3" :title="item.title" />
              </v-list>
            </v-menu>
            <v-tooltip right v-if="isInRole(0)">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mx-2"
                  :color="item.visible ? 'success' : 'error'"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ item.visible ? "mdi-eye" : "mdi-eye-off" }}
                </v-icon>
              </template>
              <span>{{
                item.visible ? $t("portfolio.Goals.visible") : $t("portfolio.Goals.visible")
              }}</span>
            </v-tooltip>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :color="item.progressStatus === 0 ? 'success' : 'error'"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-list-status
                </v-icon>
              </template>
              <span>{{
                item.progressStatus
                  ? $t("portfolio.Goals.ProgressStatus.toDo")
                  : $t("portfolio.Goals.ProgressStatus.InProgress")
              }}</span>
            </v-tooltip>
          </v-card-title>
          <v-card-subtitle v-if="isInRole(2)">
            {{ item.student.studentFullName }}
          </v-card-subtitle>
          <v-card-text>
            <article v-html="item.description" class="goal-description"></article>
          </v-card-text>
          <v-card-actions class="d-flex flex-wrap" style="position: absolute;bottom: 0;">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  :color="currentLocalDate <= item.dueDate ? 'primary' : 'error'"
                  label
                  class="ma-1 font-weight-bold"
                >
                  {{ item.dueDate | moment("YYYY/MM/DD - h:mm a ") }}
                </v-chip>
              </template>
              <span>{{ $t(`portfolio.Goals.date`) }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  color="success"
                  outlined
                  label
                  v-bind="attrs"
                  v-on="on"
                  class="ma-1 font-weight-bold"
                >
                  {{ item.createdDate | moment("YYYY/MM/DD - h:mm a ") }}
                </v-chip>
              </template>
              <span>{{ $t(`portfolio.Goals.createdDate`) }}</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <v-card flat width="400px" max-width="100%" height="auto" class="pa-11 ma-auto text-center">
          <b class="text-center ma-auto">
            {{ $t("noData") }}
          </b>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EditGoal from "@/components/portfolio/goals/editGoal.vue";
import ShowGoal from "@/components/portfolio/goals/showGoal.vue";
import addGoal from "@/components/portfolio/goals/addGoal.vue";
import FeedbackDialog from '@/components/portfolio/feedback/feedbackDialog.vue';
import CommentsDialog from '../../components/portfolio/comments/commentsDialog.vue';

export default {
  components: {
    addGoal, EditGoal, ShowGoal, FeedbackDialog, CommentsDialog
  },
  data() {
    return {
      userId: JSON.parse(localStorage.userData).guid,
      stuId: null,
      currentLocalDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      id: null,
      search: null,
      searchTag: null,
      model: {
        goalGuid: null,
        title: null,
        description: null,
        dueDate: null,
        progressStatus: false,
        visible: false
      },
      overlay: false,
      dialog: false,
      editDialog: false,
      deleteDialog: false,
      show: false,
      goals: [],
      tags: [],
      loadingData: false,
      message: "",
      page: 1,
      perPage: 8
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    async getGoals(id) {
      this.loadingData = true;
      this.goals = [];
      await this.axios
        .get(`Goal/Get?studentGuid=${id}`)
        .then(response => {
          const { data } = response.data;
          // console.log(data);
          if (Array.isArray(data)) {
            data.forEach(goal => {
              this.goals.push(goal);
            });
            if (this.goals.length === 0) {
              this.$t("portfolio.messages.empty");
            }
          }
        })
        .catch(() => {
          // console.log(err);
        })
        .finally(() => {
          this.loadingData = false;
        });
    },
    editedItem(item) {
      // eslint-disable-next-line prefer-const
      let goal = this.goals.find(a => a.goalGuid === item.goalGuid);
      if (goal) {
        // console.log(item);
        goal.description = item.description;
        goal.title = item.title;
        goal.dueDate = item.dueDate;
        goal.visible = item.visible;
        goal.progressStatus = item.progressStatus;
      }
    },
    setModel(item) {
      this.model = {
        goalGuid: null,
        title: null,
        description: null,
        dueDate: null,
        progressStatus: false,
        visible: false
      };
      this.model.dueDate = item.dueDate;
      this.model.visible = item.visible;
      this.model.progressStatus = item.progressStatus === 0;
      this.model.goalGuid = item.goalGuid;
      this.model.description = item.description;
      this.model.title = item.title;
      this.editDialog = true;
    },
    async removeModel(item) {
      // eslint-disable-next-line prefer-const
      let model = this.goals.find(a => a.goalGuid === item.goalGuid);
      const modelIndex = this.goals.findIndex(a => a.goalGuid === item.goalGuid);
      if (model) {
        this.deleteDialog = true;
        this.loadingData = true;
        await this.axios
          .post(`Goal/Delete`, { goalGuid: model.goalGuid })
          .then(() => {
            this.goals.splice(modelIndex, 1);
            this.$swal.fire({
              title: this.$t("operationAccomplishedSuccessfully"),
              text: "",
              icon: "success",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: toast => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              }
            });
            this.deleteDialog = false;
          })
          .catch(() => {
            this.$swal.fire({
              title: this.$t("AnErrorOccurredDuringTheProcess"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: toast => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              }
            });
          })
          .finally(() => {
            this.loadingData = false;
          });
      }
    }
  },
  computed: {
    visiblePages() {
      return this.goalsFilter.slice((this.page - 1) * this.perPage, this.page * this.perPage);
    },
    goalsFilter() {
      const data = this.goals;

      // if (this.isLiveOnly) {
      //   data = data.filter((item) => item.isLive === true);
      // }

      if (this.search) {
        return data.filter(item => this.search
            .toLowerCase()
            .split(" ")
            .every(v => item.title.toLowerCase().includes(v)));
      } else {
        return data;
      }
    }
  },
  created() {
    console.log(`Goals Parent`);
    this.stuId = this.$route.params.stuId;
    this.id = this.stuId || this.userId;
    this.getGoals(this.id);
  }
};
</script>

<style scoped>
/* .goal-description {
  height: 160px !important;
  width: 95% !important;
  overflow: hidden !important;
  margin: auto !important;
} */
.html-text,
.goal-description {
  position: relative;
  width: 100%;
  height: 160px !important;
  /* this code clamps based on specified lines */
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  display: -webkit-box;
}
.html-text >>> *,
.goal-description  >>> * {
  z-index: 1 !important;
  position: relative !important;
}
</style>
